<template>
  <a-modal
    width="450px"
    class="assignment-confirmation-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :closable="true"
    :visible="active"
    @cancel="onExit">
    <h3 class="flex align-center txt-22 txt-bold">
      {{ $t('components.description.surveyValidationResultsModalTitle') }}
    </h3>
    <p>
      {{ $t('components.description.surveyValidationResultsModalBody') }}
    </p>
    <div class="flex justify-space-between w100">
      <a-button
        class="mt-24"
        type="primary" ghost
        size="large"
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <a-button
        class="mt-24"
        type="primary"
        size="large"
        @click="onComplete">
        {{ $t('components.description.yesSure') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'ResultConfirmationModal',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    onExit: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
